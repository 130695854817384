<template>
    <!-- 可以被缓存的视图组件 -->
    
    <router-view v-slot="{ Component }">
        <keep-alive  >
            <component :is="Component" :key="$route.name" v-if="$route.meta.keepAlive"/>
        </keep-alive>
        <component :is="Component" :key="$route.name" v-if="!$route.meta.keepAlive"/>

    </router-view>
    <!-- <router-view></router-view> -->
 

</template>

<script>


</script>




