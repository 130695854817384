import { createRouter,createWebHistory } from 'vue-router'

const routes = [
  { 
    path: '/',
    component: ()=>import("../components/Index.vue")
  },

  { 
    path: '/user_order',
    component: ()=>import("../components/Order.vue"),
    meta: { title: '订单详情' }
  },
  { 
    path: '/order_evaluation', 
    component: ()=>import("../components/OrderEvaluation.vue")
  },

  { 
    path: '/teacher_register_37pG2LZnaE',
    component: ()=>import("../components/TeacherRegister.vue"),
    meta: { title: '老师注册' }
  },

  { 
    path: '/order_list_uEPus1kYb0',
    component: ()=>import("../components/OrderList.vue"),
    meta: { 
      title: '订单列表',
      keepAlive: true , 
    }
  },
  { 
    path: '/order_detail_CaQlPZqX3c',
    component: ()=>import("../components/OrderDetail.vue"),
    meta: { title: '订单详情' }
  },

  { 
    path: '/empty_page', 
    component: ()=>import("../components/Empty.vue"),
    meta: { title: '暂无数据' }
  },
];
 
const router = createRouter({

  history: createWebHistory(),
  routes,
  scrollBehavior(to,from,savePosition){
      
      // console.log(savePosition)
      if(savePosition){
        //解决页面从列表页跳转到详情页返回,初始在原来位置
        // console.log(savePosition)
        return savePosition;
      }else{
        //解决页面跳转后页面高度和前一个页面高度一样
        // const container = document.querySelector('#order_list');
        // return { selector: '#order_list', offset: { x: 0, y: 0 }, container };
        console.log(11)
        return {x:0,y:0}
      }
    }
    

  

})


router.beforeEach((to, from, next) => {
  window.document.title = to.meta.title;

// console.log(to.meta.title,from.meta.title)
//   if (to.meta.title == '订单列表' && from.meta.title == '订单列表'  && from.meta.title != undefined) {
  
//     from.meta.keepAlive = false
//     // to.meta.keepAlive = true
//     console.log(222)

//   }else{
//     // console.log(to.path)
//   }

    next();

})




export default router
