import { createApp } from 'vue'
import App from './App.vue'
import route from './router/index.js'
// 2. 引入组件样式
import 'vant/lib/index.css';

import preventReClick  from './FangDou.js'


const app = createApp(App)

app.use(route)
app.use(preventReClick )

app.mount('#app')
